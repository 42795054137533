import { Box } from "@mui/system";
import AddButton from "../../Common/AddButton";
import { useEffect, useState } from "react";
import {
  Modal,
  Table,
  TableBody,
  TableContainer,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import useToast from "../../../hooks/useToast";

import { StyledTableRow } from "../../Common/StyledTableRow";
import {
  deleteMaterialCategory,
  getAllMaterialCategories,
  getMaterialCategoryById,
} from "../../../api/Admin/matericalCategory";
import AddEditMaterialCategoryModel from "./MaterialCategory/AddEditMaterialCategoryModel";
import DeleteMaterialCategoryModal from "./MaterialCategory/DeleteMaterialCategoryModal";
import styled from "@emotion/styled";
import { INDUSTRY_ITEMS } from "../../../constants/constant";

const MaterialCategory = () => {
  const [paginationData, setPaginationData] = useState({
    totalItems: 0,
    pageSize: 10,
  });

  const [isMaterialModelOpen, setIsMaterialModelOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [editMaterialData, setEditMaterialData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [isDeleteMaterialModalOpen, setIsDeleteMaterialModalOpen] =
    useState(false);

  const [filterValue, setFilterValue] = useState([]);

  const [filterPopoverAnchorElTripStatus, setFilterPopoverAnchorElTripStatus] =
    useState(null);
  const isFilterPopoverOpenTripStatus = Boolean(
    filterPopoverAnchorElTripStatus
  );

  const handleFilterPopoverCloseTripStatus = () => {
    setFilterPopoverAnchorElTripStatus(null);
  };

  const handleFilterPopoverOpen = (e) => {
    setFilterPopoverAnchorElTripStatus(e.currentTarget);
  };

  const handleFilterValueChange = (e) => {
    const value = e.target.value;
    if (filterValue.includes(value)) {
      setFilterValue(filterValue.filter((filter) => filter !== value));
    } else {
      setFilterValue([...filterValue, value]);
    }
  };

  const { handleOpenToast, ToastMessage } = useToast();

  const [materialData, setMaterialData] = useState([]);

  const fetchAllMaterial = async () => {
    try {
      setIsLoading(true);
      const response = await getAllMaterialCategories(
        page + 1,
        "10",
        filterValue
      );
      if (response.status === 200) {
        setMaterialData(response?.data?.data?.materialCategories);
        setPaginationData(response?.data?.data?.pagination);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      handleOpenToast(`Vehicle data fetching failed.`, "error");
    }
  };

  const handleCloseModal = () => {
    setIsMaterialModelOpen(false);
    setEditMaterialData([]);
  };

  const handleEditMaterialData = async (index) => {
    const response = await getMaterialCategoryById(
      materialData[index].materialCategoryId
    );
    if (response.status === 200) {
      setIsMaterialModelOpen(true);
      setEditMaterialData(response?.data?.data);
    } else {
      handleOpenToast(`Material data fetching failed.`, "error");
    }
  };

  const handleDeleteMaterialTypeButtonClick = () => {
    setIsMaterialModelOpen(false);
    setIsDeleteMaterialModalOpen(true);
  };

  const handleDeleteMaterial = async () => {
    const response = await deleteMaterialCategory(
      editMaterialData?.materialCategoryId
    );
    if (response.status === 200) {
      await fetchAllMaterial();
      handleOpenToast("Material category deleted successfully", "success");
    } else {
      handleOpenToast("Material category deletion failed", "error");
    }
    handleCloseModal();
    setIsDeleteMaterialModalOpen(false);
  };

  useEffect(() => {
    fetchAllMaterial();
  }, [page, filterValue]);

  return (
    <Box>
      <ToastMessage />
      <AddButton
        name="Add Material Category"
        onClick={() => setIsMaterialModelOpen(true)}
      />
      <Modal open={isMaterialModelOpen} onClose={() => handleCloseModal()}>
        <Box>
          <AddEditMaterialCategoryModel
            handleDeleteMaterialTypeButtonClick={
              handleDeleteMaterialTypeButtonClick
            }
            handleCloseModal={handleCloseModal}
            fetchAllMaterial={fetchAllMaterial}
            handleOpenToast={handleOpenToast}
            editMaterialData={editMaterialData}
          />
        </Box>
      </Modal>
      <Modal
        open={isDeleteMaterialModalOpen}
        onClose={() => setIsDeleteMaterialModalOpen(false)}
      >
        <Box>
          <DeleteMaterialCategoryModal
            closeDeleteModal={() => setIsDeleteMaterialModalOpen(false)}
            handleDeleteMaterialTypeData={handleDeleteMaterial}
            materialData={editMaterialData}
          />
        </Box>
      </Modal>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StatusBox>
                  Industry
                  <img
                    src="/assets/icons/expand_less.svg"
                    alt="expand less transporter status"
                    style={{ cursor: "pointer" }}
                    onClick={handleFilterPopoverOpen}
                  />
                </StatusBox>
                <Popover
                  open={isFilterPopoverOpenTripStatus}
                  anchorEl={filterPopoverAnchorElTripStatus}
                  onClose={handleFilterPopoverCloseTripStatus}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <PopOverBox>
                    {INDUSTRY_ITEMS.map((industry, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterValue.includes(industry)}
                            onChange={handleFilterValueChange}
                            value={industry}
                          />
                        }
                        label={industry}
                      />
                    ))}
                    {filterValue.length > 0 && (
                      <Button
                        onClick={() => {
                          setFilterValue([]);
                        }}
                      >
                        Clear All
                      </Button>
                    )}
                  </PopOverBox>
                </Popover>
              </StyledTableCell>
              <StyledTableCell>Category Name</StyledTableCell>
              <StyledTableCell>Vehicle Preference I</StyledTableCell>
              <StyledTableCell>Vehicle Preference II</StyledTableCell>
              <StyledTableCell>Vehicle Preference III</StyledTableCell>
              <StyledTableCell>Vehicle Preference IV</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!materialData ? (
              <div style={{ marginTop: "2rem" }}>
                No material Categories data found
              </div>
            ) : (
              materialData?.length > 0 &&
              materialData?.map((material, index) => (
                <StyledTableRow
                  key={index}
                  style={{
                    textDecoration: "none",
                    marginTop: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEditMaterialData(index)}
                >
                  <TableCell
                    scope="row"
                    sx={{ color: "#6d6d6d", textAlign: "center" }}
                  >
                    {material.industry ? material.industry : "-"}
                  </TableCell>

                  <TableCell
                    scope="row"
                    sx={{ color: "#6d6d6d", textAlign: "center" }}
                  >
                    {material.materialCategory
                      ? material.materialCategory
                      : "-"}
                  </TableCell>

                  {material.vehicle?.map((vehicle, index) => (
                    <TableCell
                      key={index}
                      scope="row"
                      sx={{ color: "#6d6d6d", textAlign: "center" }}
                    >
                      {vehicle.numberOfWheels} Wheeler
                      {` (${vehicle.minWeight / 1000} T - ${
                        vehicle.maxWeight / 1000
                      } T) `}
                    </TableCell>
                  ))}
                  {[...Array(4 - material.vehicle.length)].map((_, index) => (
                    <TableCell
                      sx={{ color: "#6d6d6d", textAlign: "center" }}
                      key={index}
                      scope="row"
                    >
                      -
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={paginationData?.totalItems}
        rowsPerPage={paginationData?.pageSize}
        page={page}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ count }) => (
          <span>
            Page{" "}
            <span style={{ color: "green", fontWeight: 700 }}>{page + 1} </span>{" "}
            of {paginationData?.totalPages}
          </span>
        )}
      />
    </Box>
  );
};

export default MaterialCategory;

const PopOverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});
const StatusBox = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
});

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#333333",
    borderRight: "1px solid #c6c6c6",
    fontWeight: 700,
    borderBottom: "none",
    width: "10%",
    textAlign: "center",
  },
}));
