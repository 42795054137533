import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import { Box } from "@mui/material";

const LoadEazyQrCode = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const url = await QRCode.toDataURL("https://www.kreedalabs.com/");
        setQrCodeUrl(url);
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    };

    generateQRCode();
  }, []);

  return (
    <Box>
      {qrCodeUrl && (
        <img
          src={qrCodeUrl}
          alt="QR Code for Kreeda Labs"
          style={{ width: 100, height: 100 }}
        />
      )}
    </Box>
  );
};

export default LoadEazyQrCode;
