import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import StateCityDropdown from "../../../component/Common/StatesAndCitiesDropdown";

import {
  getCustomerProfile,
  postCustomerProfile,
} from "../../../api/Customer/profile";

import useToast from "../../../hooks/useToast";
import validator from "validator";
import useUserAvatar from "../../../hooks/useUserAvatar";
import { setCustomerProfile } from "../../../redux/slices/customerSlice";
import { useDispatch } from "react-redux";
import { logoutCustomer } from "../../../api/logout";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const StyledLabel = (props) => {
  const { label, isRequired = false } = props;
  return (
    <InputLabel sx={{ fontSize: ".875rem", color: "#333" }}>
      {label} {isRequired && <span style={{ color: "red" }}>*</span>}
    </InputLabel>
  );
};

const initialFormState = {
  name: "",
  phoneNumber: "",
  email: "",
  businessName: "",
  address: "",
  city: "",
  state: "",
  addressLine1: "",
  addressLine2: "",
};

const CustomerProfile = () => {
  const { ToastMessage, handleOpenToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formState, setFormState] = useState(initialFormState);
  const [profileImage, setProfileImage] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [AvatarComponent, imageURI] = useUserAvatar(profileImage);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const getCustomerProfileDetails = async () => {
    try {
      const response = await getCustomerProfile();
      const customer = response?.data?.customer;
      const name = customer?.fullName ?? "";
      const email = customer?.email ?? "";
      const businessName = customer?.businessName ?? "";
      const businessAddress = customer?.businessAddress ?? "";
      const addressLine1 = businessAddress?.addressLine1 ?? "";
      const addressLine2 = businessAddress?.addressLine2 ?? "";
      const city = businessAddress?.city ?? "";
      const state = businessAddress?.state ?? "";
      setCustomerPhone(customer?.phone);
      setProfileImage(customer?.profilePhoto ?? "");
      setFormState((prev) => ({
        ...prev,
        name,
        email,
        businessName,
        businessAddress,
        addressLine1,
        addressLine2,
        city,
        state,
      }));
      setSelectedCity(city);
      setSelectedState(state);
    } catch (err) {
      handleOpenToast(
        "Couldn't retrive profile details. Please refresh page",
        "error"
      );
    }
  };
  useEffect(() => {
    getCustomerProfileDetails();
  }, []);
  useEffect(() => {
    setProfileImage(imageURI);
  }, [imageURI]);

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };
  const validateUserData = (userData) => {
    let newErrors = {};
    const { fullName, email, businessName, businessAddress } = userData;

    if (!validator.isLength(fullName, { min: 4 })) {
      newErrors.name = "Name must be at least 4 characters";
    }

    if (!validator.isEmail(email)) {
      newErrors.email = "Email is not valid";
    }

    if (!validator.isLength(businessName, { min: 1 })) {
      newErrors.businessName = "This is a required field.";
    }

    if (!validator.isLength(businessAddress?.addressLine1, { min: 1 })) {
      newErrors.addressLine1 = "This is a required field.";
    }

    if (!validator.isLength(businessAddress?.state || "", { min: 1 })) {
      newErrors.state = "Please select state";
    }

    if (!validator.isLength(businessAddress?.city || "", { min: 1 })) {
      newErrors.city = "Please select city";
    }

    setErrors(newErrors);

    // If there are no errors, the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleProfileSubmit = async (event) => {
    event.preventDefault();

    const userProfileData = {
      fullName: formState.name,
      email: formState.email,
      businessName: formState.businessName,
      businessAddress: {
        addressLine1: formState.addressLine1,
        addressLine2: formState.addressLine2,
        state: selectedState,
        city: selectedCity,
      },
      profilePhoto: profileImage,
    };
    if (validateUserData(userProfileData)) {
      try {
        const res = await postCustomerProfile(userProfileData);
        dispatch(setCustomerProfile(res.data?.customer));
        handleOpenToast("Profile updated successfully");
      } catch (err) {
        handleOpenToast("Error updating profile", "error");
      }
    }
    return;
  };

  const handleLogoutCustomer = async () => {
    try {
      await logoutCustomer();
      Cookies.remove("customertoken");
      navigate("/", { replace: true });
    } catch (err) {
      handleOpenToast("Error logging out. Please try again.", "error");
    }
  };

  return (
    <Stack px={8} py={5}>
      <ToastMessage />
      <form onSubmit={handleProfileSubmit}>
        <Stack direction="column">
          <Stack direction="row" gap={8} maxWidth="lg">
            <AvatarComponent />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledLabel label="Full Name" isRequired />
                <TextField
                  size="small"
                  placeholder="Enter full name"
                  fullWidth
                  name="name"
                  value={formState.name}
                  onChange={handleFormInputChange}
                  error={errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledLabel label="Email Address" isRequired />
                <TextField
                  size="small"
                  placeholder="Email"
                  fullWidth
                  name="email"
                  value={formState.email}
                  onChange={handleFormInputChange}
                  error={errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel sx={{ fontSize: ".875rem" }}>
                  Contact Number
                </InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  sx={{ fontWeight: 700 }}
                  name="phoneNumber"
                  value={customerPhone}
                  disabled
                  onChange={handleFormInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledLabel label="Business Name" isRequired />
                <TextField
                  size="small"
                  placeholder="Enter business name"
                  fullWidth
                  name="businessName"
                  value={formState.businessName}
                  onChange={handleFormInputChange}
                  error={errors.businessName}
                  helperText={errors.businessName}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Business Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledLabel label="Address Line 1" isRequired />
                <TextField
                  size="small"
                  placeholder="Enter address line 1"
                  fullWidth
                  name="addressLine1"
                  value={formState.addressLine1}
                  onChange={handleFormInputChange}
                  error={errors.addressLine1}
                  helperText={errors.addressLine1}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledLabel label="Address Line 2" />
                <TextField
                  size="small"
                  placeholder="Enter address line 2"
                  fullWidth
                  name="addressLine2"
                  value={formState.addressLine2}
                  onChange={handleFormInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StateCityDropdown
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  setSelectedCity={setSelectedCity}
                  selectedCity={selectedCity}
                  isCitySelected={errors.city}
                  isStateSelected={errors.state}
                  isSmall={true}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack direction="row" justifyContent="space-between" pl={3} mt={10}>
            <Button
              startIcon={<PowerSettingsNewIcon />}
              sx={{ textTransform: "none", color: "red" }}
              onClick={handleLogoutCustomer}
            >
              Logout
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ paddingInline: "2rem" }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
export default CustomerProfile;
