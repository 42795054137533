import { adminApi } from "../config";

// function to get all customers
export const getAllCustomers = async (page, pageSize, search, kycStatus) => {
  try {
    const response = await adminApi.get(
      `admin/customers?page=${
        page + 1
      }&pageSize=${pageSize}&search=${search}&kycStatus=${kycStatus}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// function to get customer details
export const getCustomerById = async (id) => {
  try {
    const response = await adminApi.get(`admin/customers/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

// function to get customer trips
export const getCustomerTrips = async (
  id,
  page,
  pageSize,
  tripStatus,
  search
) => {
  try {
    const queryParam = new URLSearchParams({
      page: page + 1,
      pageSize,
      tripStatus,
      search,
    });

    const response = await adminApi.get(
      `admin/customer/trips/${id}?${queryParam}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// function to get customer transactions
export const getCustomerTransactions = async (
  id,
  page,
  pageSize,
  search,
  isPaymentDoneThroughLEPlatform
) => {
  try {
    const queryParam = new URLSearchParams({
      page: page + 1,
      pageSize,
      search,
      isPaymentDoneThroughLEPlatform,
    });

    const response = await adminApi.get(
      `admin/customer/transactions/${id}?${queryParam}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCustomerStatus = async (id, status) => {
  try {
    const response = await adminApi.patch(`admin/customer/${id}/status`, {
      status,
    });
    return response;
  } catch (error) {
    return error;
  }
};
