import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography, Modal, Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import SimpleCard from "../../Common/SimpleCard";
import IgnoreEnquiryModal from "./IgnoreEnquiryModal";
import VehicleNumberDropdown from "./VehicleNumberDropdown";
import EnquiryReQuote from "./EnquiryReQuote";
import {
  getEstimate,
  getTransporterEnquiryDetailsById,
  updateEnquiryDetailsById,
} from "../../../api/Transporter/enquiries";

import {
  createBid,
  getBidDetailsByBidId,
  updateBid,
} from "../../../api/Transporter/bid";
import { ENQUIRY_STATUS } from "../../../constants/constant";
import { setEnquiryStatusTracking } from "../../../redux/slices/transporterSlice";

export default function EnquiryDetails(props) {
  const {
    transporterId,
    transporterEnquiryId,
    enquiryStatus,
    fromDashboard,
    entityId,
    handleOpenToast,
  } = props;
  const enquiryStatusTracking = useSelector(
    (state) => state.transporter.enquiryStatusTracking
  );

  const [transporterEnquiryDetails, setTransporterEnquiryDetails] = useState(
    {}
  );
  const [isIgnoreEnquiryModalOpen, setIsIgnoreEnquiryModalOpen] =
    useState(false);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleCategory, setVehicleCategory] = useState(0);
  const [vehicleManufacturerName, setVehicleManufacturerName] = useState("");
  const [vehicleModelName, setVehicleModelName] = useState("");
  const [vehicleNumberToInfomap, setVehicleNumberToInfomap] = useState({});
  const [quoteAmount, setQuoteAmount] = useState(0);
  const [previousBidDetails, setPreviousBidDetails] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const [isEsmtLoading, setIsEsmtLoading] = useState(false);
  const [estimate, setEstimate] = useState();
  const dispatch = useDispatch();

  const transporterEnquiryById = async () => {
    try {
      if (!transporterId || !transporterEnquiryId) return;
      setIsLoading(true);
      const response = await getTransporterEnquiryDetailsById(
        transporterId,
        transporterEnquiryId
      );
      if (response.status === 200) {
        let responseData = response?.data.data;
        setTransporterEnquiryDetails(responseData);
        let bidId = responseData?.bidId;
        if (bidId) {
          const bidDetailsResponse = await getBidDetailsByBidId(bidId);
          if (bidDetailsResponse.status === 200) {
            setPreviousBidDetails(bidDetailsResponse?.data.data);
            setVehicleNumber(bidDetailsResponse?.data.data.vehicleNumber);
            setQuoteAmount(bidDetailsResponse?.data.data.amountBided);
          }
        }
      }
    } catch (error) {
      console.log("error while getting enquires", error);
    } finally {
      setIsLoading(false);
    }
  };

  const tripEstimateByVehicalCategory = async (vehicalCategory) => {
    try {
      setIsEsmtLoading(true);
      const response = await getEstimate(
        transporterId,
        transporterEnquiryId,
        vehicalCategory
      );
      if (response.status === 200) {
        setEstimate(response?.data.data.estimate);
      }
    } catch (error) {
      console.log("error while getting enquires", error);
    } finally {
      setIsEsmtLoading(false);
    }
  };

  const isValidateBidData = () => {
    if (
      transporterEnquiryDetails &&
      vehicleNumber &&
      vehicleCategory &&
      (quoteAmount >= 1 ? "null" : quoteAmount) &&
      transporterId &&
      transporterEnquiryId &&
      vehicleNumberToInfomap[vehicleNumber]
    ) {
      return true;
    }
    return false;
  };

  const handleSendBid = async () => {
    if (!isValidateBidData()) {
      handleOpenToast(`Something went wrong`, "error");
    }

    const minValue = Math.ceil(
      (estimate?.estimationDetails?.minFare / 100) * 30
    );

    if (quoteAmount === 0 || quoteAmount < minValue) {
      return handleOpenToast(`Please re-check your quote amount`, "error");
    }
    const flag =
      transporterEnquiryDetails?.customerEnquiry?.customer?.preferredVehicle
        ?.vehicleCategoryId ===
      vehicleNumberToInfomap[vehicleNumber].vehicleCategoryId
        ? "BEST_MATCH"
        : "BEST_OFFER";

    let bidData = {
      customerEnquiryId:
        transporterEnquiryDetails?.customerEnquiry?.customerEnquiryId,
      transporterEnquiryId: transporterEnquiryId,
      transporterVehicleId:
        vehicleNumberToInfomap[vehicleNumber].transporterVehicleId,
      vehicleTypeId: vehicleNumberToInfomap[vehicleNumber].vehicleTypeId,
      vehicleCategoryId:
        vehicleNumberToInfomap[vehicleNumber].vehicleCategoryId,
      vehicleNumber: vehicleNumber,
      amountBided: Number(quoteAmount),
      transporterId: transporterId,
      bidFlag: flag,
      distance: transporterEnquiryDetails?.customerEnquiry?.distance,
      vehicleCategory: vehicleCategory,
      vehicleManufacturerName: vehicleManufacturerName,
      vehicleModelName: vehicleModelName,
    };

    try {
      const response = await createBid(bidData);
      if (response.status === 201) {
        handleOpenToast("Your bid is sent successfully!", "success");
        await new Promise((resolve) => setTimeout(resolve, 5000));
        dispatch(
          setEnquiryStatusTracking(
            `${transporterEnquiryId}-bid-send-${Date.now()}`
          )
        );
      }
    } catch (error) {
      handleOpenToast(`Error sending your bid !`, "error");
    }
  };

  const handleUpdateBid = async () => {
    const bidId = previousBidDetails?._id;
    if (!quoteAmount && !transporterEnquiryDetails && !bidId) {
      handleOpenToast(`Something went wrong`, "error");
      return;
    }

    if (Number(previousBidDetails?.amountBided) <= Number(quoteAmount)) {
      handleOpenToast(
        `Updated bid amount should be less than last quote`,
        "error"
      );
      return;
    }

    let bidData = {
      customerEnquiryId:
        transporterEnquiryDetails?.customerEnquiry?.customerEnquiryId,
      amountBided: Number(quoteAmount),
      distance: Number(transporterEnquiryDetails?.customerEnquiry?.distance),
    };

    try {
      const response = await updateBid(bidId, bidData);
      if (response.status === 200) {
        handleOpenToast("Updated Quote sent successfully!", "success");
        await new Promise((resolve) => setTimeout(resolve, 5000));
        dispatch(
          setEnquiryStatusTracking(
            `${transporterEnquiryId}-bid-updated-${Date.now()}`
          )
        );
      }
    } catch (error) {
      handleOpenToast(`Error sending your bid !`, "error");
    }
  };

  const handleIgnoreEnquiry = async () => {
    try {
      const response = await updateEnquiryDetailsById(
        transporterId,
        transporterEnquiryId,
        { ignoreEnquiry: true }
      );
      if (response.status === 200) {
        setIsIgnoreEnquiryModalOpen(false);
        handleOpenToast("Enquiry ignored successfully", "success");

        await new Promise((resolve) => setTimeout(resolve, 5000));

        dispatch(
          setEnquiryStatusTracking(`${transporterEnquiryId}-enquiry-ignored`)
        );
      }
    } catch (error) {
      console.log("error while updating enquires", error);
    }
  };

  const handleQuoteAmountChange = (val) => {
    if (!isNaN(val)) {
      setQuoteAmount(val);
    }
  };

  useEffect(() => {
    transporterEnquiryById();
  }, [enquiryStatusTracking, transporterId, transporterEnquiryId]);

  useEffect(() => {
    if (
      enquiryStatus !== ENQUIRY_STATUS.YOU_QUOTED &&
      vehicleNumber &&
      vehicleCategory
    ) {
      tripEstimateByVehicalCategory(vehicleCategory);
    }
  }, [vehicleNumber]);

  const handleBlur = () => {
    if (quoteAmount < 0) {
      setQuoteAmount(0);
    } else if (quoteAmount > 100) {
      setQuoteAmount(100);
    }
  };

  const renderSetYourQuoteText = (status) => {
    if (status === ENQUIRY_STATUS.UNQUOTED) {
      return (
        <StyledGreenText>
          You have the first mover advantage!{" "}
          <span style={{ fontWeight: 400 }}>
            Offer your best quote to secure the bid
          </span>
        </StyledGreenText>
      );
    } else if (status === ENQUIRY_STATUS.QUOTED) {
      return (
        <Box>
          <StyledGreenText>
            {vehicleNumber ? (
              <>
                {transporterEnquiryDetails?.customerEnquiry?.customer
                  ?.preferredVehicle?.vehicleCategory !== vehicleCategory && (
                  <>
                    {transporterEnquiryDetails?.customerEnquiry?.offers
                      ?.bestOffer ? (
                      <>
                        Best offer for this enquiry : ₹{""}
                        {Math.round(
                          transporterEnquiryDetails?.customerEnquiry?.offers
                            ?.bestOffer?.amountBided
                        )}
                        /-
                        <StyledGreenText
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          Try to give offer lesser than this to raise your
                          chances of Booking{" "}
                        </StyledGreenText>
                      </>
                    ) : (
                      <StyledGreenText>
                        You have the first mover advantage!{" "}
                        <span style={{ fontWeight: 400 }}>
                          Offer your best quote to secure the bid
                        </span>
                      </StyledGreenText>
                    )}
                  </>
                )}

                {transporterEnquiryDetails?.customerEnquiry?.customer
                  ?.preferredVehicle?.vehicleCategory === vehicleCategory && (
                  <>
                    {transporterEnquiryDetails?.customerEnquiry?.offers
                      ?.bestMatch ? (
                      <>
                        Best match for this enquiry :₹{""}
                        {Math.round(
                          transporterEnquiryDetails?.customerEnquiry?.offers
                            ?.bestMatch?.amountBided
                        )}
                        /-
                        <StyledGreenText
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          Try to give offer lesser than this to raise your
                          chances of Booking{" "}
                        </StyledGreenText>
                      </>
                    ) : (
                      <StyledGreenText>
                        You have the first mover advantage!{" "}
                        <span style={{ fontWeight: 400 }}>
                          Offer your best quote to secure the bid
                        </span>
                      </StyledGreenText>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <StyledGreenText>
                  Please select vehicle to send the quote.
                </StyledGreenText>
              </>
            )}
          </StyledGreenText>
        </Box>
      );
    } else if (status === ENQUIRY_STATUS.YOU_QUOTED) {
      return (
        <StyledGreenText>
          {`Your last quote was ₹ ${previousBidDetails.amountBided}/- . `}
          <span style={{ fontWeight: 400 }}>
            Since you've already quoted, consider revising your offer to
            increase your chances of securing the booking.
          </span>
        </StyledGreenText>
      );
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton width="100%" height={180} animation="wave" />
      ) : (
        <>
          <StyledBox>
            {fromDashboard && (
              <Box fontWeight={700} fontSize={14} pb={2}>
                #{entityId}{" "}
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box width="calc(60% - 30px)">
                <StyledBoxContainer gap="30px">
                  <StyledText>
                    {
                      transporterEnquiryDetails?.customerEnquiry?.pickup
                        ?.location?.address
                    }
                  </StyledText>

                  <StyledBoxContainer
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ width: "20%" }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        margin: fromDashboard ? "8px" : "0px",
                      }}
                    >
                      {transporterEnquiryDetails?.customerEnquiry?.distance +
                        " km"}
                    </Typography>
                    <img
                      src="/assets/icons/distanceArrow.svg"
                      alt="addImage"
                      style={{
                        height: "20px",
                      }}
                    />
                  </StyledBoxContainer>

                  <StyledText>
                    {
                      transporterEnquiryDetails?.customerEnquiry?.dropoff
                        ?.location?.address
                    }
                  </StyledText>
                </StyledBoxContainer>
                {(enquiryStatus === ENQUIRY_STATUS.UNQUOTED ||
                  enquiryStatus === ENQUIRY_STATUS.QUOTED) && (
                  <StyledTypography
                    onClick={() => setIsIgnoreEnquiryModalOpen(true)}
                  >
                    Ignore Enquiry
                  </StyledTypography>
                )}
              </Box>

              <Box display="flex" flexDirection="column" gap="10px" width="40%">
                <StyledBoxContainer sx={{ width: "100%" }} gap="10px">
                  <SimpleCard
                    heading={
                      transporterEnquiryDetails?.customerEnquiry?.material?.name
                    }
                    subHeading="Material"
                    width="50%"
                    fromDashboard={fromDashboard}
                  />
                  <SimpleCard
                    heading={`${
                      transporterEnquiryDetails?.customerEnquiry?.weight / 1000
                    } ${
                      transporterEnquiryDetails?.customerEnquiry?.weight /
                        1000 <=
                      1
                        ? "Ton"
                        : "Tons"
                    }`}
                    subHeading="Weight"
                    width="50%"
                    fromDashboard={fromDashboard}
                  />
                </StyledBoxContainer>

                <SimpleCard
                  heading={
                    transporterEnquiryDetails?.customerEnquiry?.customer
                      ?.preferredVehicle?.vehicleCategory + " Wheeler "
                  }
                  subHeading={" Customers Vehicle Choice"}
                  width="100%"
                  fromDashboard={fromDashboard}
                />
              </Box>
            </Box>
            {enquiryStatus !== ENQUIRY_STATUS.BID_WON && (
              <Box>
                <StyledLine />
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="80px"
                  paddingTop="20px"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box
                    width="calc(50% - 80px)"
                    display="flex"
                    gap="10px"
                    flexDirection="column"
                  >
                    <StyledHeading>Assign Your Truck</StyledHeading>
                    <StyledText width="100%">
                      {`If you assign the same truck type ${
                        transporterEnquiryDetails?.customerEnquiry?.customer
                          ?.preferredVehicle?.vehicleCategory + " Wheeler"
                      } as requested by customer, you have more
                  chances of winning.`}
                    </StyledText>

                    <Box width="100%">
                      <VehicleNumberDropdown
                        vehicleNumber={vehicleNumber}
                        setVehicleNumber={setVehicleNumber}
                        setVehicleCategory={setVehicleCategory}
                        transporterId={transporterId}
                        vehicleNumberToInfomap={vehicleNumberToInfomap}
                        setVehicleNumberToInfomap={setVehicleNumberToInfomap}
                        enquiryStatus={enquiryStatus}
                        setVehicleManufacturerName={setVehicleManufacturerName}
                        setVehicleModelName={setVehicleModelName}
                      />
                    </Box>
                  </Box>
                  {vehicleNumber && (
                    <Box width="50%">
                      <Box width="100%">
                        <StyledHeading>Set Your Quote</StyledHeading>
                        {renderSetYourQuoteText(enquiryStatus)}
                      </Box>
                      <Box width="100%">
                        <EnquiryReQuote
                          vehicleNumber={vehicleNumber}
                          estimate={estimate}
                          isEsmtLoading={isEsmtLoading}
                          quoteAmount={quoteAmount}
                          onChange={(val) =>
                            handleQuoteAmountChange(Math.ceil(val))
                          }
                          handleSliderChange={(val) =>
                            setQuoteAmount(Math.ceil(val))
                          }
                          onBlur={handleBlur}
                          handleSendQuote={
                            enquiryStatus === ENQUIRY_STATUS.YOU_QUOTED
                              ? handleUpdateBid
                              : handleSendBid
                          }
                          enquiryStatus={enquiryStatus}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>{" "}
              </Box>
            )}
          </StyledBox>

          <Modal
            open={isIgnoreEnquiryModalOpen}
            onclose={() => setIsIgnoreEnquiryModalOpen(false)}
          >
            <IgnoreEnquiryModal
              transporterEnquiryDetails={transporterEnquiryDetails}
              setIsIgnoreEnquiryModalOpen={setIsIgnoreEnquiryModalOpen}
              handleIgnoreInquiry={handleIgnoreEnquiry}
            />
          </Modal>
        </>
      )}
    </>
  );
}

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: "100%",
  backgroundColor: "#DDEFDD",
  border: "1px solid #333333",
  padding: "30px",
}));

const StyledText = styled(Typography)(({ fontSize, width }) => ({
  color: "#4F4848",
  fontFamily: "Helvetica",
  fontSize: fontSize || "14px",
  fontStyle: "normal",
  fontWeight: "400",
  textTransform: "capitalize",
  width: width || "40%",
}));

const StyledTypography = styled(Typography)(() => ({
  color: "#FF3F3F",
  fontSize: "14px",
  fonWeight: "400",
  textDecoration: "underline",
  cursor: "pointer",
  display: "flex",
  paddingTop: "20px",
}));

const StyledHeading = styled(Typography)(({ color }) => ({
  color: color || "#333333",
  fontSize: "18px",
  fontWeight: "700",
  textTransform: "capitalize",
}));

const StyledGreenText = styled(Typography)(({ fontWeight }) => ({
  width: "100%",
  fontSize: "14px",
  color: "#0C7F44",
  fontWeight: fontWeight || "700",
  padding: "8px 0",
}));

const StyledLine = styled(Typography)(() => ({
  border: "1px solid #6A6A6A",
  marginTop: "16px",
}));

const StyledBoxContainer = styled(Box)(
  ({ flexDirection, gap, paddingBottom, justifyContent }) => ({
    display: "flex",
    flexDirection: flexDirection || "row",
    gap: gap || "0px",
    paddingBottom: paddingBottom || "0px",
    justifyContent: justifyContent || "center",
  })
);
