import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LOADING_STATUS } from "../constants/constant";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import ErrorScreen from "../component/Common/ErrorScreen";
import { PlacesAutocomplete } from "../component/Common/PlacesAutoComplete";
import useToast from "../hooks/useToast";
import {
  createEnquiryFromWhatsapp,
  getCustomerEnquiryDetailsById,
  getMaterialTypes,
  getTimeSlots,
  getVehicleRecommendation,
} from "../api/Customer/enquiry";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useMapsService } from "../hooks/useMapsService";

const initialFormState = {
  pickup: "",
  dropoff: "",
  weight: "",
  quantity: "",
  material: "",
  vehicleCategory: "",
  vehicleType: "",
  date: null,
  slot: "",
  username: "",
  weightUnit: "KG",
};

const WhatsappEnquiryRebook = () => {
  const { customerId, enquiryId } = useParams();
  const { handleOpenToast, ToastMessage } = useToast();
  const [formData, setFormData] = useState(initialFormState);
  const [enquiryData, setEnquiryData] = useState(null);
  const [isEnquiryDataFetched, setIsEnquiryDataFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [materialNameList, setMaterialNameList] = useState([]);
  const [chosenMaterial, setChosenMaterial] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [timeSlotList, setTimeSlotList] = useState([]);

  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);
  const [distance, setDistance] = useState("");

  const tryRefetchingEnquiryData = () => {
    retriveDataForEnquiry();
  };

  const renderViews = () => {
    switch (isEnquiryDataFetched) {
      case LOADING_STATUS.LOADING:
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            py={20}
            m="auto"
          >
            <CircularProgress sx={{ color: "green", fontSize: 24 }} />
          </Stack>
        );
      case LOADING_STATUS.LOADED:
        return (
          <RebookForm
            formData={formData}
            materialNameList={materialNameList}
            vehicles={vehicles}
            setFormData={setFormData}
            timeSlotList={timeSlotList}
            distance={distance}
            pickup={source}
            dropoff={destination}
            chosenMaterial={chosenMaterial}
          />
        );
      case LOADING_STATUS.FAILED:
        return <ErrorScreen onRetry={tryRefetchingEnquiryData} />;
    }
  };
  const updateFormData = (enquiry) => {
    const { pickup, dropoff, material, quantity, weight, customer, distance } =
      enquiry;
    setDistance(distance);
    // alert(customer.preferredVehicle.vehicleCategory)
    setFormData({
      pickup: pickup.location.address,
      dropoff: dropoff.location.address,
      material: material.name,
      weight: weight > 1000 ? weight / 1000 : weight,
      weightUnit: weight > 1000 ? "TONS" : "KG",
      vehicleCategory:
        customer.preferredVehicle.vehicleCategory === 1
          ? "No Preference"
          : customer.preferredVehicle.vehicleCategory,
      username: customer?.customerName ?? "",
      quantity: quantity ?? "",
      date: null,
      slot: "",
    });
    setSource(pickup.location);
    setDestination(dropoff.location);
  };
  const retriveDataForEnquiry = async () => {
    setIsEnquiryDataFetched(LOADING_STATUS.LOADING);
    try {
      const [materialTypeResponse, enquiry, timeSlotResponse] =
        await Promise.all([
          getMaterialTypes(),
          getCustomerEnquiryDetailsById(customerId, enquiryId),
          getTimeSlots(),
        ]);
      const materialTypeForDropdown = materialTypeResponse.data.data.map(
        (type) => ({
          id: type.materialTypeId,
          value: type?.materialName,
          material: {
            name: type.materialName,
            category: type.productCategory,
            categoryId: type.materialCategory.id,
          },
        })
      );
      const timeSlotForDropdown = timeSlotResponse.data.data.map(
        (slot, idx) => ({
          id: idx,
          value: slot.timeSlot,
          startTime: slot.startTime,
        })
      );
      setMaterialNameList(materialTypeForDropdown);
      setTimeSlotList(timeSlotForDropdown);
      setEnquiryData(enquiry.data.data.enquiry);
      setChosenMaterial(enquiry.data.data.enquiry.material);
      updateFormData(enquiry.data.data.enquiry);
      setIsEnquiryDataFetched(LOADING_STATUS.LOADED);
    } catch (err) {
      setIsEnquiryDataFetched(LOADING_STATUS.FAILED);
      handleOpenToast("Something went wrong. Please refresh page.", "error");
    }
  };

  useEffect(() => {
    retriveDataForEnquiry();
  }, []);
  useEffect(() => {
    if (enquiryData)
      getVehicleRecommendation(
        enquiryData.material.categoryId,
        formData.weightUnit === "KG" ? formData.weight : formData.weight * 1000
      )
        .then((d) => {
          setVehicles(d.data.data);
        })
        .catch((e) => {});
  }, [formData.weight, formData.weightUnit]);

  return (
    <Box maxWidth="sm" m="auto" py={3}>
      <ToastMessage />
      <Box px={3}>
        <img
          src="/assets/icons/LZ-logo-face.svg"
          style={{ marginBottom: ".35rem", marginRight: ".25rem" }}
          alt="logoface"
        />
        <img src="/assets/icons/LZ-logo.svg" alt="logo" />
      </Box>
      <Box px={3} mt={3}>
        <Typography gutterBottom fontWeight={700} color="#333">
          Hi, {enquiryData?.customer?.customerName}
        </Typography>
        <Typography fontSize={14}>
          Continue your enquiry journey here
        </Typography>
        {renderViews()}
      </Box>
    </Box>
  );
};
export default WhatsappEnquiryRebook;

const labelStyle = { fontSize: ".835rem", color: "#333", fontWeight: 700 };

const RebookForm = ({
  formData,
  materialNameList,
  vehicles,
  setFormData,
  timeSlotList,
  distance = "",
  pickup,
  dropoff,
  chosenMaterial,
}) => {
  const { customerId, enquiryId } = useParams();
  const navigate = useNavigate();
  const { getLatLng, calculateDistance } = useMapsService();
  const [isEnquirySubmitted, setIsEnquirySubmitted] = useState("NOT");
  const { handleOpenToast, ToastMessage } = useToast();
  const [pickUpPlaceId, setPickUpPlaceId] = useState("");
  const [dropOffPlaceId, setDropOffPlaceId] = useState("");
  const [materialOpted, setMaterialOpted] = useState(chosenMaterial);
  const [recommendedTimeSlots, setRecommendedTimeSlots] =
    useState(timeSlotList);
  let distanceInKm = distance;

  const validateInputs = () => {
    if (!formData?.pickup) {
      handleOpenToast("Select valid pickup location", "error");
      return;
    }
    if (!formData?.dropoff) {
      handleOpenToast("Select valid drop off location", "error");
      return;
    }
    if (!formData?.date) {
      handleOpenToast("Please pick a valid date", "error");
      return;
    }
    if (!formData?.slot) {
      handleOpenToast("Please choose a valid slot", "error");
      return;
    }

    if (!formData?.weight) {
      handleOpenToast("Write a valid weight", "error");
      return;
    }
    if (!formData?.material) {
      handleOpenToast("Select a valid material type", "error");
      return;
    }
    if (!formData?.vehicleCategory) {
      handleOpenToast("Select a valid vehicle", "error");
      return;
    }
    if (!formData?.username) {
      handleOpenToast("Enter a valid name", "error");
      return;
    }
    return true;
  };

  useEffect(() => {
    if (!vehicles.length) {
      setFormData((p) => ({ ...p, vehicleCategory: "No Preference" }));
    } else
      setFormData((p) => ({
        ...p,
        vehicleCategory: vehicles[0].numberOfWheels,
      }));
  }, [vehicles.length]);

  useEffect(() => {
    if (formData.date) {
      const selectedDate = moment(formData.date).startOf("day");
      const currentDate = moment().startOf("day");

      if (selectedDate.isSame(currentDate)) {
        const currentTime = moment();

        const newTimeSlots = timeSlotList.filter((slot) => {
          const slotStartTime = moment(slot.startTime, "hh:mm A");
          return slotStartTime.isAfter(currentTime);
        });

        setRecommendedTimeSlots(newTimeSlots);
      } else {
        setRecommendedTimeSlots(timeSlotList);
      }
    }
  }, [formData.date]);

  const handlePickupSelect = (place, placeId) => {
    setFormData((prev) => ({ ...prev, pickup: place }));
    setPickUpPlaceId(placeId);
  };
  const handleDropoffSelect = (place, placeId) => {
    setFormData((prev) => ({ ...prev, dropoff: place }));
    setDropOffPlaceId(placeId);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "material") {
      setMaterialOpted(
        materialNameList.find((item) => item.value === value).material
      );
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateEnquiry = async () => {
    setIsEnquirySubmitted("INPROGRESS");
    if (validateInputs()) {
      let datePart = formData?.date;
      let timePart = formData?.slot.split("-")[0];
      let time24 = timePart.includes("AM")
        ? timePart.substring(0, 5)
        : (parseInt(timePart.substring(0, 2)) % 12) +
          12 +
          timePart.substring(2, 5);
      let dateTimeString = `${datePart}T${time24}+05:30`;

      const pickUpData = pickUpPlaceId
        ? await getLatLng(pickUpPlaceId)
        : pickup;

      const dropOffData = dropOffPlaceId
        ? await getLatLng(dropOffPlaceId)
        : dropoff;

      const calculateNewDistance = async (pickup, dropoff) => {
        try {
          const res = await calculateDistance(pickup, dropoff);
          setFormData((p) => ({ ...p, distance: res }));

          return res;
        } catch (err) {
          handleOpenToast("Cannot calculate distance. Try again", "error");
          setIsEnquirySubmitted("NOT");
          return err;
        }
      };

      distanceInKm =
        pickUpPlaceId || dropOffPlaceId
          ? await calculateNewDistance(pickUpData.address, dropOffData.address)
          : distance;

      let requestBody = {
        material: materialOpted,
        weight:
          formData.weightUnit === "KG"
            ? formData?.weight
            : 1000 * formData?.weight,
        pickupDate: new Date(dateTimeString).toISOString(),
        vehicleCategory: 1,
        pickupSlot: formData?.slot,
        quantity: formData?.quantity,
        pickup: {
          location: pickUpData,
        },
        dropoff: {
          location: dropOffData,
        },
        distance: distanceInKm,
        customerId,
        customerName: formData.username,
      };
      if (formData?.vehicleCategory !== "No Preference") {
        requestBody = {
          ...requestBody,
          vehicleCategory: formData?.vehicleCategory,
          vehicleCategoryId: vehicles.filter(
            (vehicle) => vehicle.numberOfWheels === formData?.vehicleCategory
          )[0]?.vehicleCategoryId,
        };
      }
      // console.log(requestBody)
      //   return;

      try {
        const response = await createEnquiryFromWhatsapp(requestBody);
        setIsEnquirySubmitted("DONE");
        navigate(`/enquiry-form/${customerId}/success`, {
          state: {
            details: {
              enqId: response.data.data._id,
              pickup: formData.pickup,
              dropoff: formData.dropoff,
              distance: distanceInKm,
              userId: customerId,
              isRebook: true,
            },
          },
        });
      } catch (err) {
        handleOpenToast(err?.message ?? "Couldn't create enquiry !", "error");
        setIsEnquirySubmitted("NOT");
      }
    } else {
      setIsEnquirySubmitted("NOT");
    }
  };

  return (
    <Stack direction="column" gap={1} mt={2}>
      <ToastMessage />
      <Box>
        <InputLabel sx={labelStyle}>Pickup Location</InputLabel>
        <PlacesAutocomplete
          onPlaceSelect={handlePickupSelect}
          // error={Boolean(firstPageErrors.pickup)}
          value={formData.pickup}
        />
      </Box>
      <Box>
        <InputLabel sx={labelStyle}>Dropoff Location</InputLabel>
        <PlacesAutocomplete
          onPlaceSelect={handleDropoffSelect}
          // error={Boolean(firstPageErrors.dropoff)}
          value={formData.dropoff}
        />
      </Box>
      <Stack>
        <InputLabel sx={labelStyle}>Material Name</InputLabel>
        <Select
          displayEmpty
          value={formData.material}
          // error={Boolean(firstPageErrors.material)}
          onChange={handleInputChange}
          name="material"
          sx={{ color: formData.material ? "#333" : "#ADADAD" }}
        >
          <MenuItem value="" sx={{ display: "none" }}>
            Select
          </MenuItem>
          {materialNameList.map((material) => (
            <MenuItem value={material.value} key={material.id}>
              {material.value}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Box>
        <InputLabel sx={labelStyle}>Weight</InputLabel>
        <Stack direction="row" spacing={2}>
          <TextField
            name="weight"
            placeholder="Enter Weight"
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: 0,
              },
            }}
            fullWidth
            value={formData.weight}
            onChange={handleInputChange}
            //   error={Boolean(firstPageErrors.weight)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Select
                    value={formData.weightUnit}
                    onChange={(e) =>
                      setFormData((p) => ({ ...p, weightUnit: e.target.value }))
                    }
                    sx={{
                      width: "100px",
                      borderColor: "red",
                      "& .MuiOutlinedInput-notchedOutline , &.Mui-focused .MuiOutlinedInput-notchedOutline ,& .MuiOutlinedInput-root":
                        {
                          borderColor: "#EAEAEA !important",
                        },
                    }}
                  >
                    <MenuItem value="KG">KG</MenuItem>
                    <MenuItem value="TONS">TONS</MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Box>
      <Box>
        <InputLabel sx={labelStyle}>Vehicle Category</InputLabel>
        <Select
          value={formData.vehicleCategory}
          fullWidth
          name="vehicleCategory"
          onChange={handleInputChange}
          displayEmpty
          sx={{
            color: formData.vehicleCategory ? "#333" : "#ADADAD",
          }}
          // error={Boolean(firstPageErrors.vehicleCategory)}
        >
          <MenuItem value="" sx={{ display: "none" }}>
            Select
          </MenuItem>
          {/* <MenuItem value={1}>No Preference</MenuItem> */}
          {vehicles.length > 0 ? (
            vehicles.map((item, idx) => (
              <MenuItem key={idx} value={item.numberOfWheels}>
                {item.numberOfWheels} wheelers
              </MenuItem>
            ))
          ) : (
            <MenuItem value="No Preference">No Preference</MenuItem>
          )}
        </Select>
      </Box>
      <Box>
        <Box>
          <InputLabel sx={labelStyle}>Quantity</InputLabel>
          <TextField
            placeholder="Quantity (optional)"
            fullWidth
            value={formData.quantity}
            name="quantity"
            onChange={handleInputChange}
          />
        </Box>
      </Box>
      <Box>
        <InputLabel sx={labelStyle}>Pickup Date</InputLabel>
        <Stack>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              slots={{
                openPickerIcon: EditCalendarRoundedIcon,
              }}
              format="DD/MM/YYYY"
              value={moment(formData.date)}
              minDate={moment(new Date())}
              defaultValue={moment(new Date())}
              name="date"
              onChange={(newValue) => {
                const dateStr = newValue.format("YYYY-MM-DD");
                setFormData((prev) => ({ ...prev, date: dateStr }));
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // height: "2.75rem",
                  "&.Mui-error": {
                    "& fieldset": {
                      borderColor: "#c6c6c6",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      <Box>
        <InputLabel sx={labelStyle}>Pickup Slot</InputLabel>
        <Select
          name="slot"
          onChange={handleInputChange}
          fullWidth
          displayEmpty
          value={formData.slot}
          sx={{
            color: formData.vehicleCategory ? "#333" : "#ADADAD",
          }}
          //   error={Boolean(secondPageErrors.slot)}
        >
          <MenuItem value="" sx={{ display: "none" }}>
            Select
          </MenuItem>
          {recommendedTimeSlots.map((slot) => (
            <MenuItem key={slot.id} value={slot.value}>
              {slot.value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={3}>
        <InputLabel sx={labelStyle}>Name</InputLabel>
        <TextField
          placeholder="Enter name"
          fullWidth
          value={formData.username}
          name="username"
          onChange={handleInputChange}
          //   error={Boolean(secondPageErrors.username)}
        />
      </Box>
      <Button
        disabled={isEnquirySubmitted === "INPROGRESS"}
        variant="contained"
        onClick={() => {
          handleCreateEnquiry();
        }}
      >
        Submit
      </Button>
    </Stack>
  );
};
