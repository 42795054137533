import React from "react";
import { Box } from "@mui/material";
import HeaderWithUnderline from "./HeaderWithUnderline";
import NameDescription from "./NameDescription";
import { formatNumber } from "../../../../utils/formatNumber";

export default function EstimationDetails(props) {
  const { enquiry } = props;

  return (
    <Box>
      <HeaderWithUnderline
        header={
          enquiry?.estimationDetails ? "Estimation Details" : "Estimation Send"
        }
      />

      {enquiry?.estimationDetails ? (
        <Box
          sx={{
            marginTop: "12px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <NameDescription
            name="Estimate fare"
            description={
              enquiry?.estimationDetails &&
              `₹ ${formatNumber(
                enquiry?.estimationDetails?.minFare
              )} - ${formatNumber(enquiry?.estimationDetails?.maxFare)} `
            }
            padding="10px 0px"
          />
          <NameDescription
            name="Estimate per Ton"
            description={
              enquiry?.estimationDetails &&
              `₹ ${(
                (Math.floor(enquiry?.estimationDetails?.minFare) /
                  enquiry?.weight) *
                1000
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} - ${(
                (Math.floor(enquiry?.estimationDetails?.maxFare) /
                  enquiry?.weight) *
                1000
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            }
            marginLeft="40px"
            padding="10px 0px"
          />
          <NameDescription
            name="Platform charge"
            description={`₹ ${formatNumber(
              enquiry?.estimationDetails?.platformCharge
            )}`}
            marginLeft="40px"
            padding="10px 0px"
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <NameDescription
            name=""
            description="Customer didn’t select any truck"
            fontSize="16px"
          />
        </Box>
      )}
    </Box>
  );
}
