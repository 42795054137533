import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { DialogBtn } from "../Common/DialogBtn";
import { CustomTextInput } from "../Common/CustomTextInput";
import { useSelector, useDispatch } from "react-redux";
import { updateContactPersonDetails } from "../../api/Customer/enquiry";
import { setEnquiryStatusTracking } from "../../redux/slices/customerSlice";
import { SAVING_STATUS } from "../../constants/constant";

export default function PaymentSuccessDialog({
  isOpen,
  handleClose,
  paymentAmount,
  tripId,
  handleOpenToast,
}) {
  const dispatch = useDispatch();
  const customerId = useSelector((state) => state.customer.id);
  const [isContactDetailsUploaded, setIsContactDetailsUploaded] = useState("");
  const [pickupContactPersonName, setPickupContactPersonName] = useState("");
  const [pickupContactPersonNumber, setPickupContactPersonNumber] =
    useState("");
  const [dropOffContactPersonName, setDropOffContactPersonName] = useState("");
  const [dropOffContactPersonNumber, setDropOffContactPersonNumber] =
    useState("");
  const [pickupCPNameError, setPickupCPNameError] = useState(" ");
  const [pickupCPNumberError, setPickupCPNumberError] = useState(" ");
  const [dropoffCPNameError, setDropoffCPNameError] = useState(" ");
  const [dropOffCPNumberError, setDropOffCPNumberError] = useState(" ");

  const validateData = () => {
    const String = "Field cannot be empty";

    if (!pickupContactPersonName) {
      setPickupCPNameError(String);
    }
    if (!pickupContactPersonNumber) {
      setPickupCPNumberError(String);
    }
    if (!dropOffContactPersonName) {
      setDropoffCPNameError(String);
    }
    if (!dropOffContactPersonNumber) {
      setDropOffCPNumberError(String);
    }
  };

  const handleContactName = (e, setField, setError) => {
    var value = e.target.value;
    setField(value);
    if (value.length > 50) {
      setError("Name should not exceed 50 characters");
    } else {
      setError("");
    }
  };
  const handleContactNumber = (e, setField, setError) => {
    var value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    if (value.length <= 10) {
      setField(value);
    }

    if (value.trim() !== "" && value.length < 10) {
      setError("Invalid contact number");
    } else {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateData();
    if (
      pickupCPNameError !== "" ||
      pickupCPNumberError !== "" ||
      dropoffCPNameError !== "" ||
      dropOffCPNumberError !== ""
    ) {
      return;
    }

    const ContactPersonDetails = {
      pickupContactPerson: {
        name: pickupContactPersonName,
        phoneNumber: pickupContactPersonNumber,
      },
      dropOffContactPerson: {
        name: dropOffContactPersonName,
        phoneNumber: dropOffContactPersonNumber,
      },
    };

    if (tripId) {
      handleClose();

      setIsContactDetailsUploaded(SAVING_STATUS.SENDING);
      const response = await updateContactPersonDetails(
        customerId,
        tripId,
        ContactPersonDetails
      );
      if (response.status === 200) {
        handleClose();
        setIsContactDetailsUploaded(SAVING_STATUS.SAVED);
        handleOpenToast(
          "Contact person's details saved successfully.",
          "success"
        );

        await new Promise((resolve) => setTimeout(resolve, 5000));

        dispatch(setEnquiryStatusTracking(`${tripId}-trip-created`));
      } else {
        setIsContactDetailsUploaded(SAVING_STATUS.FAILED);
        handleOpenToast(`Error saving contact person's details.`, "error");
      }
    } else {
      handleClose();
      setIsContactDetailsUploaded(SAVING_STATUS.FAILED);
      handleOpenToast(`Something went wrong.`, "error");
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth={800}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          overflow: "hidden",
        }}
      >
        <Box sx={{ width: "800px", maxHeight: "545px", overflow: "hidden" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            p={5}
            pt={3}
            sx={{ background: "#DDEFDD" }}
          >
            <Box>
              <Typography
                style={{
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#0C7F44",
                }}
              >
                Payment Successful!
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 400, color: "#333333" }}
              >
                Your transaction with LoadEazy has been completed successfully.
              </Typography>
            </Box>
            <Box display="flex" alignItems="baseline">
              <img
                src="/assets/icons/currencyRupeeGreen.svg"
                alt="curruncy"
                width={22}
                height={22}
              />
              <Typography
                style={{
                  color: "#0C7F44",
                  fontSize: 32,
                  fontWeight: 700,
                  marginLeft: 4,
                }}
              >
                {paymentAmount}
              </Typography>

              <img
                src="/assets/icons/checkCircle.svg"
                alt="check"
                width={24}
                height={24}
                style={{ marginLeft: 16 }}
              />
            </Box>
          </Box>
          <Box px={5} pt={3}>
            <Typography
              sx={{ fontSize: 24, fontWeight: 700, color: "#333333" }}
            >
              Contact Information Required
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 400, color: "#808080" }}
            >
              Please ensure that you enter the contact numbers of the pickup and
              drop-off persons correctly, as the OTP will be sent to these
              mobile numbers during the pickup and drop-off processes.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box>
              <Box display="flex" justifyContent="space-between" mt={3}>
                <CustomTextInput
                  label="Pickup Contact Person Name"
                  handleInputChange={(e) =>
                    handleContactName(
                      e,
                      setPickupContactPersonName,
                      setPickupCPNameError
                    )
                  }
                  value={pickupContactPersonName}
                  error={pickupCPNameError}
                  containerStyle={{ marginLeft: 5 }}
                />
                <CustomTextInput
                  label="Pickup contact person's mobile number"
                  containerStyle={{ marginRight: 5, marginLeft: 2 }}
                  handleInputChange={(e) =>
                    handleContactNumber(
                      e,
                      setPickupContactPersonNumber,
                      setPickupCPNumberError
                    )
                  }
                  value={pickupContactPersonNumber}
                  error={pickupCPNumberError}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <CustomTextInput
                  label="Drop-Off Contact Person Name"
                  handleInputChange={(e) =>
                    handleContactName(
                      e,
                      setDropOffContactPersonName,
                      setDropoffCPNameError
                    )
                  }
                  value={dropOffContactPersonName}
                  error={dropoffCPNameError}
                  containerStyle={{ marginLeft: 5 }}
                />
                <CustomTextInput
                  label="Drop-Off contact person's mobile number"
                  handleInputChange={(e) =>
                    handleContactNumber(
                      e,
                      setDropOffContactPersonNumber,
                      setDropOffCPNumberError
                    )
                  }
                  value={dropOffContactPersonNumber}
                  error={dropOffCPNumberError}
                  containerStyle={{ marginRight: 5, marginLeft: 2 }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                marginTop: 3,
              }}
            >
              <DialogBtn
                disabled={
                  pickupCPNameError !== "" ||
                  pickupCPNumberError !== "" ||
                  dropoffCPNameError !== "" ||
                  dropOffCPNumberError !== "" ||
                  isContactDetailsUploaded === SAVING_STATUS.SENDING
                }
                type="submit"
                title="Save Contact"
                onClick={() => "save clicked"}
                buttonStyle={{
                  color: "#FFFFFF",
                  background: "#0C7F44",
                  "&:hover": {
                    backgroundColor: "#0C7F44",
                    opacity: 0.8,
                  },
                }}
              />
              <Typography
                onClick={handleClose}
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  color: "#5386E4",
                  cursor: "pointer",
                  mt: 2,
                  pb: 3,
                }}
              >
                {"I will do it later >>"}
              </Typography>
            </Box>
          </form>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
