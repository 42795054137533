import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, Select, MenuItem, Skeleton } from "@mui/material";
import { getVehicalDropdownInfo } from "../../../api/Transporter/enquiries";
import { ENQUIRY_STATUS } from "../../../constants/constant";

const VehicleNumberSelect = styled(Select)(() => ({
  height: "48px",
  width: "100%",
  color: "#333",
  padding: "0px 12px",
  backgroundColor: "#fff",
  cursor: "pointer",
  borderRadius: "5px",
  fontSize: "16px",
  fontWeight: "700",

  "& .MuiInputBase-input": {
    padding: "0px",
  },
}));

const VehicleNumberSelectedValue = styled(Box)(({ placeholder }) => ({
  color: placeholder ? "#ADADAD" : "#333",
  fontSize: "14px",
  fontWeight: "700",
  textTransform: "capitalize",
}));

export default function VehicleNumberDropdown({
  vehicleNumber,
  setVehicleNumber,
  setVehicleCategory,
  transporterId,
  vehicleNumberToInfomap,
  setVehicleNumberToInfomap,
  enquiryStatus,
  setVehicleModelName,
  setVehicleManufacturerName,
}) {
  const [vehicleNumbers, setVehicleNumbers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (enquiryStatus === "YOU_QUOTED") {
      return;
    }
    try {
      setLoading(true);
      const response = await getVehicalDropdownInfo(transporterId);
      if (response.status === 200) {
        const responseData = response?.data.data;
        const vehicleNumbersArray = responseData.map(
          (vehicle) => vehicle.vehicleNumber
        );
        var vehicleObjectsMap = responseData.reduce((acc, vehicle) => {
          acc[vehicle.vehicleNumber] = vehicle;
          return acc;
        }, {});
        setVehicleNumberToInfomap(vehicleObjectsMap);
        setVehicleNumbers(vehicleNumbersArray);
      }
    } catch (error) {
      console.log("error while getting enquires", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setVehicleNumber(e.target.value);
    setVehicleCategory(vehicleNumberToInfomap[e.target.value].vehicleCategory);
    setVehicleManufacturerName(
      vehicleNumberToInfomap[e.target.value].vehicleManufacturerName
    );
    setVehicleModelName(
      vehicleNumberToInfomap[e.target.value].vehicleModelName
    );
  };
  return (
    <VehicleNumberSelect
      disabled={
        enquiryStatus === ENQUIRY_STATUS.YOU_QUOTED ||
        enquiryStatus === ENQUIRY_STATUS.BID_WON
      }
      displayEmpty
      value={vehicleNumber}
      onChange={handleChange}
      onOpen={fetchData}
      renderValue={(selected) => {
        if (!selected) {
          return (
            <VehicleNumberSelectedValue>Select</VehicleNumberSelectedValue>
          );
        }
        return (
          <VehicleNumberSelectedValue>{selected}</VehicleNumberSelectedValue>
        );
      }}
    >
      {loading ? (
        <MenuItem>
          <Skeleton width="100%" height={40} animation="wave" />
        </MenuItem>
      ) : (
        vehicleNumbers.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))
      )}
    </VehicleNumberSelect>
  );
}
