import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Modal,
  Typography,
  Skeleton,
  Grid,
  Button,
} from "@mui/material";
import { StyledDeliverySectionBox } from "./StyledDeliverySectionBox";
import { StyledDeliverySectionTextPrimary } from "./StyledDeliverySectionTextPrimary";
import { StyledDetailsSectionBox } from "./StyledDetailsSectionBox";
import { StyledDetailsSectionTextPrimary } from "./StyledDetailsSectionTextPrimary";
import { StyledDetailsSectionTextSecondary } from "./StyledDetailsSectionTextSecondary";
import { formatDate, truncateString } from "../../utils/textFormat";
import { DialogBtn } from "../Common/DialogBtn";
import { StyledTextPrimary } from "../Common/StyledTextPrimary";
import { StyledTextSecondary } from "../Common/StyledTextSecondary";
import ContactDialog from "./ContactDialog";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  LOADING_STATUS,
  TRIP_STEPPER_STATES,
  TRIP_STATUS,
  UPCOMING_TRIP_STATUS,
  CANCELLED_TRIP_STEPPER_STATES,
} from "../../constants/constant";
import { getrTripDetails } from "../../api/Customer/trips";
import { useSelector } from "react-redux";
import ErrorScreen from "../Common/ErrorScreen";
import CallBackModal from "../Common/CallBackModal";
import { useTimer } from "react-timer-hook";
import CustomStepperProgressBar from "../Common/CustomStepperProgressBar";
import moment from "moment";
import Invoice from "./PaymentDocuments/Invoice";

export const BookingsCollapse = ({ index, trip, fromDashboard, entityId }) => {
  const customerId = useSelector((state) => state.customer.id);
  const supportNumber = useSelector(
    (state) => state.supportNumbers?.customerPortal
  );
  const bookingStatusTracking = useSelector(
    (state) => state.customer.bookingStatusTracking
  );

  const [isCancelBookingDialogOpen, setIsCancelBookingDialogOpen] =
    useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
  const [tripDetails, setTripDetails] = useState({});
  const [isTripDetailsFetched, setIsTripDetailsFetched] = useState("");
  const [activeStep, setActiveStep] = useState(3);
  const [showDriverDetails, setShowDriverDetails] = useState(false);
  const cancelTripArray = [
    TRIP_STATUS.CANCELLED_BY_ADMIN,
    TRIP_STATUS.CANCELLED_BY_CUSTOMER,
    TRIP_STATUS.CANCELLED_BY_TRANSPORTER,
  ];

  const deliveredDate = () => {
    if (tripDetails?.tripCompletedTimeStamp) {
      return moment(tripDetails?.tripCompletedTimeStamp).format(
        "Do MMMM YYYY hh:mm A"
      );
    }
    if (
      tripDetails?.truckInTransitTimeStamp >
      tripDetails?.deliveryExpectedTimeStamp
    ) {
      return "";
    }
    if (
      tripDetails?.materialLoadedTimeStamp >
      tripDetails?.deliveryExpectedTimeStamp
    ) {
      return "";
    }
    if (
      tripDetails?.driverAtPickUpTimeStamp >
      tripDetails?.deliveryExpectedTimeStamp
    ) {
      return "";
    }

    return tripDetails?.deliveryExpectedTimeStamp
      ? `Expected By ${moment(tripDetails?.deliveryExpectedTimeStamp).format(
          "Do MMMM YYYY"
        )}`
      : "";
  };

  const CUSTOMER_TRIP_STEPPER_LABELS = [
    {
      label: "Booking Accepted",
      description:
        tripDetails?.bookingAcceptedTimestamp &&
        moment(tripDetails?.bookingAcceptedTimestamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "Driver At Pickup Location",
      description:
        tripDetails?.driverAtPickUpTimeStamp &&
        moment(tripDetails?.driverAtPickUpTimeStamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "Material Loaded",
      description:
        tripDetails?.materialLoadedTimeStamp &&
        moment(tripDetails?.materialLoadedTimeStamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "In Transit",
      description:
        tripDetails?.truckInTransitTimeStamp &&
        moment(tripDetails?.truckInTransitTimeStamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "Delivered",
      description: deliveredDate(),
    },
  ];

  const CANCELLED_TRIP_STEPPER_LABELS = [
    {
      label: "Booking Accepted",
      description:
        tripDetails?.bookingAcceptedTimestamp &&
        moment(tripDetails?.bookingAcceptedTimestamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: `Cancelled By ${
        tripDetails?.cancellation?.requestedBy === "CUSTOMER"
          ? "You"
          : tripDetails?.cancellation?.requestedBy === "TRANSPORTER"
          ? "Transporter"
          : "Admin"
      }`,
      description:
        tripDetails?.cancellation?.cancelledAt &&
        moment(tripDetails?.cancellation?.cancelledAt).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
  ];

  const handleOpenCancelBookingDialog = () => {
    setIsCancelBookingDialogOpen(true);
  };

  const handleCloseCancelBookingDialog = () => {
    setIsCancelBookingDialogOpen(false);
  };

  const handleOpenSupportDialog = () => {
    setIsSupportDialogOpen(true);
  };

  const handleCloseSupportDialog = () => {
    setIsSupportDialogOpen(false);
  };

  const handleOpenContactDialog = () => {
    setIsContactDialogOpen(true);
  };

  const handleCloseContactDialog = () => {
    setIsContactDialogOpen(false);
  };

  const renderContactSection = () => {
    if (Object.keys(tripDetails?.pickupContactPerson).length !== 0) {
      return (
        <Typography
          onClick={handleOpenContactDialog}
          sx={{
            color: "#5386E4",
            fontSize: 14,
            fontWeight: 700,
            textDecoration: "underline",
            cursor: "pointer",
            display: "inline",
          }}
        >
          Edit pickup and drop-off person's contact details
        </Typography>
      );
    } else {
      return (
        <DialogBtn
          title="Add Contact Details"
          buttonStyle={{
            color: "#FF3F3F",
            background: "#FFF",
            border: "1px solid #FF3F3F",
          }}
          onClick={handleOpenContactDialog}
        />
      );
    }
  };

  const fetchCustomerTripDetails = async () => {
    try {
      setIsTripDetailsFetched(LOADING_STATUS.LOADING);
      const response = await getrTripDetails(customerId, trip?.id);
      if (response.status === 200) {
        const details = response?.data.data;
        setTripDetails(details);
        setIsTripDetailsFetched(LOADING_STATUS.LOADED);
        setActiveStep(
          details?.tripStatus?.includes("CANCELLED")
            ? CANCELLED_TRIP_STEPPER_STATES[details?.tripStatus]
            : TRIP_STEPPER_STATES[details?.tripStatus]
        );
        const showDriverDetails = isShowDriverInfoPreTripTimeExpired(
          details?.driver?.showDriverInfoPreTripTime
        );
        setShowDriverDetails(showDriverDetails);
      }
    } catch (error) {
      setIsTripDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  const isShowDriverInfoPreTripTimeExpired = (timer) => {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(timer).getTime();
    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    return remainingSeconds === 0;
  };

  const getRemainingTimeInSeconds = () => {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(
      tripDetails?.driver?.showDriverInfoPreTripTime
    ).getTime();

    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    const time = new Date();
    time.setSeconds(remainingSeconds);
    return time;
  };

  const handleTimerExpired = () => {
    setShowDriverDetails(true);
  };

  const expiryTime = new Date(
    tripDetails?.driver?.showDriverInfoPreTripTime
  ).getTime();
  const autoStart = expiryTime >= Date.now();
  const { seconds } = useTimer({
    autoStart: autoStart,
    expiryTimestamp: getRemainingTimeInSeconds(),
    onExpire: () => handleTimerExpired(),
  });

  useEffect(() => {
    fetchCustomerTripDetails();
  }, [showDriverDetails, trip?.id, bookingStatusTracking]);

  const [isPaymentReceipt, setIsPaymentReceipt] = useState(false);
  const [isDownloadPdfClicked, setIsDownloadPdfClicked] = useState(false);
  const invoiceRef = useRef();

  const handleGeneratePDF = () => {
    if (invoiceRef.current) {
      invoiceRef.current.generatePDF();
    }
  };

  useEffect(() => {
    handleGeneratePDF();
  }, [isPaymentReceipt, isDownloadPdfClicked]);

  if (isTripDetailsFetched === LOADING_STATUS.LOADED) {
    return (
      <>
        <StyledOuterBox
          cancelTrip={cancelTripArray.includes(tripDetails?.tripStatus)}
        >
          {fromDashboard && (
            <Box fontWeight={700} fontSize={14}>
              #{trip?.tripId}{" "}
            </Box>
          )}
          <Grid container>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={5}>
                  <Box>
                    <StyledDeliverySectionBox sx={{ whiteSpace: "normal" }}>
                      <StyledDeliverySectionTextPrimary
                        sx={{ fontSize: fromDashboard && "12px" }}
                      >
                        {tripDetails?.enquiryDetails?.pickup?.location?.address}
                      </StyledDeliverySectionTextPrimary>
                    </StyledDeliverySectionBox>
                    {Object.keys(tripDetails?.pickupContactPerson).length !==
                      0 && (
                      <Typography
                        variant="p"
                        sx={{
                          color: "#000",
                          pl: 1,
                          fontSize: fromDashboard && "12px",
                        }}
                      >
                        {`${tripDetails?.pickupContactPerson?.name}, ${tripDetails?.pickupContactPerson?.phoneNumber}`}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <StyledAddressSectionBox>
                    <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
                      {`${tripDetails?.enquiryDetails?.distance}km`}
                    </Typography>
                    <img
                      src="/assets/icons/distanceArrowLong.svg"
                      alt="distance"
                      style={{ maxWidth: fromDashboard ? "60px" : "100px" }}
                    />
                  </StyledAddressSectionBox>
                </Grid>
                <Grid item xs={5}>
                  <Box>
                    <StyledDeliverySectionBox sx={{ textAlign: "right" }}>
                      <StyledDeliverySectionTextPrimary
                        sx={{ fontSize: fromDashboard && "12px" }}
                      >
                        {
                          tripDetails?.enquiryDetails?.dropoff?.location
                            ?.address
                        }
                      </StyledDeliverySectionTextPrimary>
                    </StyledDeliverySectionBox>
                    {Object.keys(tripDetails?.dropOffContactPerson).length !==
                      0 && (
                      <Typography
                        variant="p"
                        sx={{
                          color: "#000",
                          pl: 1,
                          fontSize: fromDashboard && "12px",
                        }}
                      >
                        {`${tripDetails?.dropOffContactPerson?.name}, ${tripDetails?.dropOffContactPerson?.phoneNumber}`}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <StyledBox>
                <StyledDetailsSectionBox
                  sx={{ background: "#FFFFFF", padding: "8px 16px" }}
                >
                  <StyledDetailsSectionTextPrimary
                    sx={{ fontSize: fromDashboard && "10px" }}
                  >
                    {tripDetails?.enquiryDetails?.material?.name
                      ? truncateString(
                          tripDetails?.enquiryDetails?.material?.name,
                          40
                        )
                      : "--"}
                  </StyledDetailsSectionTextPrimary>
                  <StyledDetailsSectionTextSecondary
                    sx={{ mt: 0.5, fontSize: fromDashboard && "10px" }}
                  >
                    Material
                  </StyledDetailsSectionTextSecondary>
                </StyledDetailsSectionBox>
                <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                  <StyledDetailsSectionTextPrimary
                    sx={{ fontSize: fromDashboard && "10px" }}
                  >
                    {tripDetails?.enquiryDetails?.weight
                      ? `${Number(tripDetails?.enquiryDetails?.weight) / 1000}T`
                      : "--"}
                  </StyledDetailsSectionTextPrimary>
                  <StyledDetailsSectionTextSecondary
                    sx={{ mt: 0.5, fontSize: fromDashboard && "10px" }}
                  >
                    Weight
                  </StyledDetailsSectionTextSecondary>
                </StyledDetailsSectionBox>
              </StyledBox>
            </Grid>
          </Grid>

          {UPCOMING_TRIP_STATUS.includes(tripDetails?.tripStatus) && (
            <Box sx={{ padding: "12px 8px" }}>
              {renderContactSection(index)}
            </Box>
          )}

          <StyledDivider />
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledAmountSectionBox>
                <Box
                  sx={{
                    paddingRight: fromDashboard ? 4 : 8,
                    borderRight: "1px dashed #6A6A6A",
                  }}
                >
                  <StyledTextPrimary>Total amount</StyledTextPrimary>
                  <StyledTextSecondary sx={{ fontSize: 18 }} mt={2}>
                    {`₹${tripDetails?.customerAmounts?.totalAmount}`}
                  </StyledTextSecondary>
                </Box>
                <Box>
                  <StyledTextPrimary>Amount Paid</StyledTextPrimary>

                  {tripDetails?.customerAmounts?.balanceReceivable > 0 &&
                    !tripDetails?.tripStatus.includes("CANCELLED") && (
                      <StyledTextPrimary mt={2}>
                        Balance Payable
                      </StyledTextPrimary>
                    )}
                  {tripDetails?.tripStatus === "CANCELLED_BY_CUSTOMER" && (
                    <StyledTextPrimary mt={2}>
                      Penalty Charges
                    </StyledTextPrimary>
                  )}
                  {tripDetails?.customerPaymentStatus === "REFUNDED" && (
                    <StyledTextPrimary mt={2}>Refunded</StyledTextPrimary>
                  )}
                  {tripDetails?.customerAmounts?.totalAmount >
                    tripDetails?.cancellation?.penaltyCharge && (
                    <StyledTextPrimary mt={2}>To Be Refund</StyledTextPrimary>
                  )}
                </Box>

                <Box ml={5}>
                  {tripDetails?.customerTransactions?.map(
                    (transaction, index) => (
                      <Box display="flex" gap={3} key={index}>
                        <StyledTextSecondary sx={{ color: "#0C7F44" }}>
                          {`₹${transaction.amount}`}
                        </StyledTextSecondary>
                        <StyledTextPrimary sx={{ color: "#808080" }}>
                          {`paid on ${formatDate(
                            transaction.transactedAt,
                            "DD-MM-YYYY HH:mm"
                          )}`}
                        </StyledTextPrimary>
                      </Box>
                    )
                  )}
                  {tripDetails?.customerAmounts?.balanceReceivable > 0 &&
                    !tripDetails?.tripStatus.includes("CANCELLED") && (
                      <Box display="flex" gap={3} mt={2}>
                        <StyledTextSecondary sx={{ color: "#FF3F3F" }}>
                          {`₹${tripDetails?.customerAmounts?.balanceReceivable}`}
                        </StyledTextSecondary>
                        <StyledTextPrimary sx={{ color: "#808080" }}>
                          {`to be paid by ${formatDate(
                            tripDetails.customerPaymentSettlementDeadline,
                            "DD-MM-YYYY HH:mm"
                          )}`}
                        </StyledTextPrimary>
                      </Box>
                    )}
                  {tripDetails?.tripStatus === "CANCELLED_BY_CUSTOMER" && (
                    <Box display="flex" gap={3} mt={2}>
                      <StyledTextSecondary sx={{ color: "#FF3F3F" }}>
                        {`₹${tripDetails?.cancellation?.penaltyCharge}`}
                      </StyledTextSecondary>
                    </Box>
                  )}
                  {tripDetails?.customerPaymentStatus === "REFUNDED" && (
                    <Box display="flex" gap={3} mt={2}>
                      <StyledTextSecondary sx={{ color: "#0C7F44" }}>
                        {`₹${
                          tripDetails?.customerAmounts?.totalAmount -
                          tripDetails?.cancellation?.penaltyCharge
                        }`}
                      </StyledTextSecondary>
                    </Box>
                  )}
                  {tripDetails?.customerAmounts?.amountReceived >
                    tripDetails?.cancellation?.penaltyCharge && (
                    <Box display="flex" gap={3} mt={2}>
                      <StyledTextSecondary sx={{ color: "#0C7F44" }}>
                        {`₹${
                          tripDetails?.customerAmounts?.amountReceived -
                          tripDetails?.cancellation?.penaltyCharge
                        }`}
                      </StyledTextSecondary>
                    </Box>
                  )}
                </Box>
              </StyledAmountSectionBox>
              {UPCOMING_TRIP_STATUS.includes(tripDetails?.tripStatus) && (
                <Box sx={{ padding: "12px 8px" }} alignSelf="end">
                  <StyledCancelBookingBtn
                    onClick={handleOpenCancelBookingDialog}
                  >
                    Cancel Booking
                  </StyledCancelBookingBtn>
                </Box>
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              sx={{ mt: 2 }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: 500,
                  fontSize: "1rem",
                  padding: "0.25rem 1rem",
                  height: "36px",
                  width: "100%",
                  whiteSpace: "nowrap",
                  mb: 1,
                }}
                onClick={() => {
                  setIsPaymentReceipt(true);
                  setIsDownloadPdfClicked(!isDownloadPdfClicked);
                }}
              >
                PAYMENT RECEIPT
              </Button>

              {tripDetails?.customerPaymentStatus === "PAID" &&
                tripDetails?.tripStatus === "TRIP_COMPLETED" && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: 500,
                      fontSize: "1rem",
                      padding: "0.25rem 1rem",
                      height: "36px",
                      width: "100%",
                    }}
                    onClick={() => {
                      setIsPaymentReceipt(false);
                      setIsDownloadPdfClicked(!isDownloadPdfClicked);
                    }}
                  >
                    INVOICE
                  </Button>
                )}

              <Invoice
                ref={invoiceRef}
                tripDetails={tripDetails}
                isPaymentReceipt={isPaymentReceipt}
              />
            </Box>
          </Box>

          <StyledDivider />

          <Box sx={{ padding: "24px 8px", width: "100%" }}>
            <CustomStepperProgressBar
              steps={
                tripDetails?.tripStatus.includes("CANCELLED")
                  ? CANCELLED_TRIP_STEPPER_LABELS
                  : CUSTOMER_TRIP_STEPPER_LABELS
              }
              activeStep={activeStep}
            />
          </Box>
          <StyledDivider />
          {!tripDetails?.tripStatus.includes("CANCELLED") && (
            <StyledDriverSectionBox>
              <Box>
                <StyledDriverSectionTitle
                  sx={{ fontSize: fromDashboard && "16px" }}
                >
                  Driver Details
                </StyledDriverSectionTitle>
                {!showDriverDetails ? (
                  <StyledDriverSectionTextPrimary
                    sx={{ mt: 1, fontSize: fromDashboard && "16px" }}
                  >
                    Driver details will be provided shortly.
                  </StyledDriverSectionTextPrimary>
                ) : (
                  <Box display="flex" gap={3}>
                    <StyledDriverSectionTextPrimary
                      sx={{ fontSize: fromDashboard && "16px" }}
                    >
                      Name :{" "}
                      <span style={{ fontWeight: 700 }}>
                        {tripDetails?.driver?.driverName}
                      </span>
                    </StyledDriverSectionTextPrimary>
                    <StyledDriverSectionTextPrimary
                      sx={{ fontSize: fromDashboard && "16px" }}
                    >
                      Contact :{" "}
                      <span style={{ fontWeight: 700 }}>
                        {tripDetails?.driver?.driverContact}
                      </span>
                    </StyledDriverSectionTextPrimary>
                    <StyledDriverSectionTextPrimary
                      sx={{ fontSize: fromDashboard && "16px" }}
                    >
                      Vehicle Number :{" "}
                      <span style={{ fontWeight: 700 }}>
                        {" "}
                        {tripDetails?.truck?.VehicleNumber}
                      </span>
                    </StyledDriverSectionTextPrimary>
                  </Box>
                )}
              </Box>
              <Box alignSelf="end">
                <DialogBtn
                  onClick={handleOpenSupportDialog}
                  title="Support"
                  buttonStyle={{
                    color: "#333333",
                    background: "#FFF",
                    border: "1px solid #333333",
                  }}
                />
              </Box>
            </StyledDriverSectionBox>
          )}
        </StyledOuterBox>

        <ContactDialog
          open={isContactDialogOpen}
          handleClose={handleCloseContactDialog}
          tripDetails={tripDetails}
        />

        <Modal
          open={isCancelBookingDialogOpen}
          onClose={handleCloseCancelBookingDialog}
        >
          <CallBackModal
            title={`To cancel this booking please call our support team.`}
            number={supportNumber}
            closeCallBackModal={handleCloseCancelBookingDialog}
          />
        </Modal>
        <Modal open={isSupportDialogOpen} onClose={handleCloseSupportDialog}>
          <CallBackModal
            title={`For any queries related to this trip, please call our support team.`}
            number={supportNumber}
            closeCallBackModal={handleCloseSupportDialog}
          />
        </Modal>
      </>
    );
  } else if (isTripDetailsFetched === LOADING_STATUS.LOADING) {
    return (
      <Box p={3}>
        <Box display="flex" gap={2}>
          <Skeleton variant="rounded" width={200} height={60} />
          <Skeleton variant="rounded" width={200} height={60} />
          <Skeleton variant="rounded" width={200} height={60} />
          <Skeleton variant="rounded" width={200} height={60} />
        </Box>
        <Box width={630} pt={2}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <ErrorScreen onRetry={fetchCustomerTripDetails} />;
      </Box>
    );
  }
};

const StyledCancelBookingBtn = styled(Typography)`
  color: #ff3f3f;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`;

const StyledDivider = styled(Divider)`
  margin: 0 1px;
  background-color: #6d6d6d;
  font-weight: 400;
  opacity: 1;
  height: 1px;
`;

const StyledDriverSectionTitle = styled(Typography)`
  color: #333333;
  font-size: 18px;
  font-weight: 700;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledDriverSectionTextPrimary = styled(Typography)`
  color: #4f4848;
  font-size: 18px;
  font-weight: 400;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledDriverSectionBox = styled(Box)`
  display: flex;
  padding: 12px 8px;
  align-items: center;
  justify-content: space-between;
`;

const StyledAddressSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const StyledAmountSectionBox = styled(Box)`
  padding: 24px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
`;

const StyledOuterBox = styled(Box)`
  background: ${(props) => (props.cancelTrip ? "#F6EDED" : "#ddefdd")};
  padding: 16px 24px;
`;
